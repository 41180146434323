const CONFIG = {
  title: 'bina.az',
  gtmContainerId: 'GTM-54QWK2',
  fbAppId: '114513531950627',
  asset_host: 'bina.azstatic.com',
  fallback_token: 'BINAAZ',
  protocol: process.env.BINAAZ_PROTOCOL || 'https',
  host: 'binaaz-master.azstage.in',
  languages: ['az', 'ru'],
  debugMode: false,
  contacts: {
    tel: '(012) 599-08-02',
    tel_url: 'tel:+994125990802',
    tel_additional: '(012) 505-08-02',
    tel_additional_url: 'tel:+994125050802',
    email: 'bina@bina.az',
    instagramLink: 'https://www.instagram.com/bina.az.official/',
    facebookLink: 'https://www.facebook.com/www.bina.az'
  },
  currentYear: new Date().getFullYear()
};

export default CONFIG;
